<template><section><html><head></head><body><h1>Elasticsearch核心概念</h1>
<p><strong>作        者：张正武</strong></p>
<p><strong>时        间：2023年04月</strong></p>
<p><strong>部        门：研发四部</strong></p>
<p><strong>公        司：北京学科网（股份）有限公司</strong></p>
<p><strong>适用人群：开发以及想学习相关内容的人员</strong></p>
<hr>
<h2>一、倒排索引</h2>
<h3>1、搜索引擎应该具备那些要求</h3>
<ol>
<li>
<h4>查询速度快</h4>
<ul>
<li>
<p>高效的压缩算法</p>
</li>
<li>
<p>快速的编码和解码速度</p>
</li>
</ul>
</li>
<li>
<h4>结果准确</h4>
<ul>
<li>
<p>BM25(7.0版本之后)：BM25(BM=best matching)是TF-IDF的优化版本</p>
<img src="C:\Users\xiaowu\AppData\Roaming\Typora\typora-user-images\image-20230105103310085.png" alt="image-20230105103310085" style="zoom: 50%;">
</li>
<li>
<p>TF-IDF(7.0版本之前)：其中tf称为词频，idf为逆文档频率。</p>
<img src="C:\Users\xiaowu\AppData\Roaming\Typora\typora-user-images\image-20230105103543469.png" alt="image-20230105103543469" style="zoom: 50%;">
</li>
</ul>
</li>
<li>
<h4>检索结果丰富</h4>
<ul>
<li>召回率</li>
</ul>
</li>
</ol>
<h3>2、面向海量数据如何达到“搜索引擎”级别的查询效率？</h3>
<p><img src="C:%5CUsers%5Cxiaowu%5CAppData%5CRoaming%5CTypora%5Ctypora-user-images%5Cimage-20230105111100301.png" alt="image-20230105111100301"></p>
<p>如图显示，没有索引情况下执行图中Sql时要进行全表扫描（10亿数据量），存在大量的IO操作导致性能损耗。通常的解决方案实在courseName字段上增加“索引”。</p>
<ol>
<li>
<h4>MySql的索引结构</h4>
<ul>
<li>
<h5>B Trees（多路平衡查找树）</h5>
<p><img src="https://img-blog.csdnimg.cn/20201120171422666.png?x-oss-process=image/watermark,type_ZmFuZ3poZW5naGVpdGk,shadow_10,text_aHR0cHM6Ly9ibG9nLmNzZG4ubmV0L3J1bm5pbmdfcG9yaw==,size_16,color_FFFFFF,t_70#pic_center" alt="在这里插入图片描述"></p>
</li>
<li>
<h5>B+Trees</h5>
<p><img src="https://img-blog.csdnimg.cn/20201120171356980.png?x-oss-process=image/watermark,type_ZmFuZ3poZW5naGVpdGk,shadow_10,text_aHR0cHM6Ly9ibG9nLmNzZG4ubmV0L3J1bm5pbmdfcG9yaw==,size_16,color_FFFFFF,t_70#pic_center" alt="在这里插入图片描述"></p>
</li>
</ul>
<ol start="2">
<li>
<h4>MySql索引能解决大数据检索的问题吗？</h4>
<ul>
<li>索引往往字段很长，如果使用B-Trees,树的层级很深，IO很可怕。B+Trees中最小的存储单元是页（page）,一个页的大小是16kb。</li>
<li>索引可能失效。%在前的这种情况也是进行全表扫描，索引没有生效。%在后面这种情况，到索引生效了。</li>
<li>精确度差。如果取消前%，变成了前缀匹配，能匹配到的数据将大大降低。</li>
</ul>
</li>
</ol>
<h3>3、Lucene</h3>
<ol>
<li>
<h4>全文检索概念</h4>
<p>索引系统通过扫描文章中的每一个词，对其创建索引，指明在文章中出现的次数和位置，当用户查询时，索引系统过就会根据事先建立的索引进行查找，并将查找的结果反馈给用户的检索方式。</p>
<p><img src="C:%5CUsers%5Cxiaowu%5CAppData%5CRoaming%5CTypora%5Ctypora-user-images%5Cimage-20230105141828339.png" alt="image-20230105141828339"></p>
</li>
<li>
<h4>Lucene存储与检索简介</h4>
<p>​    Lucene的一个Index会由一个或多个sub-index构成，sub-index被称为Segment，每个segment中包含多个documents文件，一个segment中会有完整的正向索引和反向索引。在搜索时，Lucene会遍历这些segments，以segments为基本单位独立搜索每个segments文件，而后再把搜索结果合并。</p>
<p>​    而Segment设计思想，与LSM类似但又有些不同，继承了LSM（Log Structured Merge Trees）中数据写入的优点，但是在查询上只能提供近实时而非实时查询。Lucene中的数据写入会先写内存的一个Buffer（类似LSM的MemTable，但是不可读），当Buffer内数据到一定量后会被flush成一个Segment，每个Segment有自己独立的索引，可独立被查询，但数据永远不能被更改。</p>
<p>​    Index的查询需要对多个Segment进行查询并对结果进行合并，还需要处理被删除的文档，为了对查询进行优化，Lucene会有策略对多个Segment进行合并，这点与LSM对SSTable的Merge类似。</p>
<p>​    Segment在被flush或commit之前，数据保存在内存中，是不可被搜索的，这也就是为什么Lucene被称为提供近实时而非实时查询的原因。</p>
</li>
<li>
<h4>倒排索引</h4>
<h5>3.1 倒排索引（Inverted Index）也叫反向索引，有反向索引必有正向索引。通俗地来讲，正向索引是通过key找value，反向索引则是通过value找key。</h5>
<p><img src="C:%5CUsers%5Cxiaowu%5CAppData%5CRoaming%5CTypora%5Ctypora-user-images%5Cimage-20230105140958760.png" alt="image-20230105140958760"></p>
<p><strong>假设有一下几条数据：</strong></p>
<table>
<thead>
<tr>
<th>ID</th>
<th>Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>人教版必修一语文</td>
</tr>
<tr>
<td>2</td>
<td>新课标语文</td>
</tr>
<tr>
<td>3</td>
<td>新课标数学必修一</td>
</tr>
</tbody>
</table>
<p><strong>那么Elastic建立的索引如下：</strong></p>
<table>
<thead>
<tr>
<th>term index</th>
<th>term dictionary</th>
<th>posting list</th>
</tr>
</thead>
<tbody>
<tr>
<td></td>
<td>人教版</td>
<td>1</td>
</tr>
<tr>
<td></td>
<td>语文</td>
<td>1,2</td>
</tr>
<tr>
<td></td>
<td>必修一</td>
<td>1,3</td>
</tr>
<tr>
<td></td>
<td>新课标</td>
<td>2,3</td>
</tr>
<tr>
<td></td>
<td>数学</td>
<td>3</td>
</tr>
</tbody>
</table>
<p>如上所示：Elasticsearch给Name字段建立了一个倒排索引，“人教版、语文……”这些是Term。而“1,2,3”就是Posting List。Postinglist就是一个int的数组，存储了所有符合某项Term的文档Id。通过Posting list这种索引方式课时很快进行查找。</p>
<h5>3.2  Term dictionary</h5>
<p>Elasticsearch为了能快速找到某个Term，将所有的Term排个序，用二分法查找Term。这个就是Term Dictionary。</p>
<h5>3.3 Term Index介绍</h5>
<p>如果Term太多，Term Dictionary也会很大，全部放在内存不现实，只能部分存储到磁盘上。这是又出现了新的问题，磁盘寻道次数太多也会严重影响查找效率，为了减少磁盘寻道次数来提高查询性能，于是有了Term Index，就像字典里的索引页一样，A开头的有哪些Term，分别在哪页，可以理解Term Index是一颗树：</p>
<p><img src="http://www.mybatis.cn/usr/uploads/2020/12/2715145526.png" alt="index2.png"></p>
<p>这棵树不会包含所有的Term，它包含的是Term的一些前缀。通过Term Index可以快速地定位到Term Dictionary的某个offset，然后从这个位置再往后顺序查找。如下图所示：</p>
<p><img src="http://www.mybatis.cn/usr/uploads/2020/12/413251517.png" alt="index3.png"></p>
<p>Term Index不需要存下所有的Term，而仅仅是它们的一些前缀与Term Dictionary的Block之间的映射关系，再结合相关的压缩技术，可以使Term Index缓存到内存中。从Term Index查到对应的Term Dictionary的Block位置之后，再去磁盘上找Term，大大减少了磁盘随机读的次数。</p>
</li>
</ol>
</li>
</ol>
<h2>二、FOR压缩算法</h2>
<ol>
<li>
<h4>基础概念</h4>
<pre v-pre=""><code v-pre="">1Int 占用 4Byte；
位（比特位）：bit（binary digit）（简写：b），是计算机数据存储最小的单位，二进制中，0或者1就是一个位（比特位）bit。
字节：Byte（简写：B），是计算机信息技术用于计量存储容量的一种计量单位，通常情况下一字节等于八位，也就是 →  1Byte = 8bit = 1B = 8b
1B（byte，字节）= 8 bit；
1KB（Kibibyte，千字节）=1024B= 2^10 B；
1MB（Mebibyte，兆字节，百万字节，简称“兆”）=1024KB= 2^20 B；
1GB（Gibibyte，吉字节，十亿字节，又称“千兆”）=1024MB= 2^30 B；
</code></pre>
<p>​    FOR算法的核心思想是用减法来削减数值大小，从而达到降低空间存储。假设V(n)表示数组中第n个字段的值，那么经过FOR算法压缩的数值V(n)=V(n)-V(n-1)。也就是说存储的是后一位减去前一位的差值。存储是也不再按照int来计算了，而是看这个数组的最大值需要占用多少bit来计算。</p>
<p>​    具体我们通过一个例子来体会：比如数组是73,300,302,332,342,372，原本需要4 * 6 byte = 24byte = 192bit。压缩后：73,227,2,30,11,29
​    这些数中227是最大的，需要8bit（227 &lt; 2^8）来盛装，那么每个数值都不会超过8bit，所以需要的大小是6 * 8bit=48bit，我们把8bit的容器理解为一个箱子，总共需要6个箱子，所有箱子占48bit，但是这并不是我们的总大小，因为相比较于原数组，我们引入了一个箱子的概念，那么除了箱子数，我们还需要记录每个箱子的大小，所以需要有一个数来记录箱子大小，这里注意我们规定盛装大小不超过256bit，因此箱子大小值最大不超过2^8，即箱子大小值占用不超过8bit，因此总共的大小是48bit+8bit = 56bit</p>
<p>​    可以看到压缩后大小由192bit降到了56bit，已经有很大改善了，但是这还不是FOR算法的终点，观察这组数中最大值227,后一位最小值是2，两者相差很大，2实际上只需要1bit来盛装，那么能不能进一步压缩呢？答案是可以，只是不再需要做差值，直接将数组分组，将其拆分为：73,227 | 2,30,11,29；那么占用空间就变成了73,227箱子大小8bit，2,30,11,29中最大30，箱子大小为5bit
​    因此数组总大小为28bit + 45bit = 36bit，另外不要忘记这里因为分成两组，还需要单独记录两组箱子的大小值，所以总大小是36bit+2*8bit= 52bit</p>
</li>
<li>
<h4>算法图解</h4>
<p><img src="C:%5CUsers%5Cxiaowu%5CAppData%5CRoaming%5CTypora%5Ctypora-user-images%5Cimage-20230414151038541.png" alt="image-20230414151038541"></p>
</li>
</ol>
<p><img src="https://img-blog.csdnimg.cn/4042b03a935a4aa6a5cbf13f3e068861.png?x-oss-process=image/watermark,type_ZHJvaWRzYW5zZmFsbGJhY2s,shadow_50,text_Q1NETiBAd3VfNTU1NTU=,size_20,color_FFFFFF,t_70,g_se,x_16" alt="在这里插入图片描述"></p>
<p>​    可以看到大小又变小了，但是思考一个问题：是不是还可以进行压缩？是越小越好吗？是可以再压缩，但是我们还要考虑解码的问题，数据压缩后是要使用的，因此需要解码，压缩得越深，解码越耗时，因此不是越小越好，那么在哪里取一个平衡，这就是通过计算机动态计算的，计算方法这里不做深一步讨论了，大家理解这个概念即可</p>
<p>以上就是FOR算法的概念，总结一下：
（1）数组元素值为与前一位的差值V(n)=V(n)-V(n-1)，n=2,3,4…
（2）计算数组中最大值所需占用的大小
（3）计算数组是否需要拆分，计算拆分后每组的最大值所需占用的大小并记录</p>
<h2>三、RBM压缩算法</h2>
<ol>
<li>
<h4>基础概念</h4>
</li>
</ol>
<p>​    有了FOR算法为什么需要别的算法呢？说明FOR算法本身是有缺陷的，那么思考一下FOR算法的缺陷在哪里？FOR算法的核心是用减法来缩减数值大小，但是减法一定能缩减大小吗？但数值大小很大时，减法能够达到的效果是不明显的，比如100W,200W,300W,相减后是100W,100W,100W，依然很大，这时的压缩效果很不理想，所以引入了RBM算法。那么大家再思考一下，既然减法不能满足，那么还有什么方法能够更快地减小数值大小呢？没错，就是除法！</p>
<p>​    RBM的核心就是通过除法来缩减数值大小，但是并不是直接的相除。比如数组为1000,62101,131385,191173,196658其中196658的二进制表示为：</p>
<pre v-pre=""><code v-pre="">0000 0000 0000 0011 0000 0000 0011 0010
</code></pre>
<p>然后将其高16位和低16位分别转换为10进制：</p>
<pre v-pre=""><code v-pre="">0000 0000 0000 0011 -&gt; 3
0000 0000 0011 0010 -&gt; 50
</code></pre>
<p>​    那么196658就转换成了(3,50)的表示形式，其效果就相当于除以2^16,商3余50.这里的计算用位运算会更快更好理解，除以2^n相当于将这个数的二进制向右位移n位（不含符号位）,并且用0补足空位。容易得出196658二进制右移16位后为</p>
<pre v-pre=""><code v-pre="">0000 0000 0000 0011
</code></pre>
<p>​    也就是其高16位，前面用0补足，而被位移顶替掉的就是其余数</p>
<pre v-pre=""><code v-pre="">0000 0000 0011 0010
</code></pre>
<p>​    因为商和余数都不超过16位，那么我们最大用16bit来存储足够了。也就是short类型。因此商和余数都可以用一个short来盛装，那么所有的商就是一个short[]，所有的余数就是一个short[ ] [ ]；</p>
<pre v-pre=""><code v-pre="">将原数组除以2^16得：
(0,1000),(0,62101),(2,313),(2,980),(2,60101),(3,50)
转化为二维数组盛装
0: [1000,62101]
2: [313,980,60101]
3: [50]
</code></pre>
<p>​    我们把每一个商所对应的余数short[]称之为一个容器Container，使用上述所说的short盛装也称为ArrayContainer。我们也容易观察发现到，每一个Container实际上都是有序数值数组，是不是能够联想到什么？</p>
<ul>
<li>数组还能进行压缩吗？</li>
<li>数组能用FOR算法再压缩吗？</li>
<li>有别的方式再进行压缩吗？</li>
</ul>
<p>​    首先回答前两个问题：数组肯定可以压缩，而且正是我们需要去做的；用FOR算法在这里进行压缩是可以的，不算错，但是我们说不合适，正如在FOR算法中介绍的那样，压缩的同时我们还有考虑解码时的效率，其实这里已经经过除法做了一次处理了，那么再用减法做一次处理，再解码时效率会降低不少，所以我们追求的是一种解码更加容器，但又能具备压缩能力的方法。</p>
<ol start="2">
<li>
<h4>算法图解</h4>
<p><img src="C:%5CUsers%5Cxiaowu%5CAppData%5CRoaming%5CTypora%5Ctypora-user-images%5Cimage-20230106141654032.png" alt="image-20230106141654032"></p>
</li>
</ol>
<p>通过上述的bit存储的方式来存储数据，就是使用bitmap的形式来存储数据，了解这个知识后我们再来看之前的问题，以下的二维数组，每一个Container中的数据当量足够多时我们认为他是有序连续的：
0: 1000,62101
2: 313,980,60101
3: 50</p>
<p><img src="https://img-blog.csdnimg.cn/bf9488366adf4ae98ddb8dc392d800a1.png?x-oss-process=image/watermark,type_ZHJvaWRzYW5zZmFsbGJhY2s,shadow_50,text_Q1NETiBAd3VfNTU1NTU=,size_20,color_FFFFFF,t_70,g_se,x_16" alt="img"></p>
<p>因此我们就可以使用bitmap来存储数据，按照规定一个Container的最大值是65534（这里为什么最大值是65534，思考一下，如果不明白往上看看原数组是怎么处理的）,也就需要65535bit=8k的容器来存储，当然bitmap有个很明显的缺点，那就是无论Container中有多少个数，都要占用8k的大小，所以当数量不超过65535bit /16bit = 4096个时，使用short (16bit)来存储更划算，当每个Container的数量超过4096个时使用bitmap更加划算，那么使用bitmap的Container称为BitmapContainer</p>
<p><img src="https://img-blog.csdnimg.cn/2f105051fda74969807a9c5274fcd4fd.png?x-oss-process=image/watermark,type_ZHJvaWRzYW5zZmFsbGJhY2s,shadow_50,text_Q1NETiBAd3VfNTU1NTU=,size_20,color_FFFFFF,t_70,g_se,x_16" alt="在这里插入图片描述"></p>
<p>还有一个Container叫RunContainer,专门用来解决连续数组存储的，比如[1,2,3,100W]，那么可以表示为[1,100W]，只存储一个最小值和最大值，如果数组是如下形式
[1,2,3,4,5,100,101,102,999,1000,1001]
就会被拆分为三段：[1,5],[100,102],[999,1001]
综上Container有三种：ArrayContainer,BitmapContainer,RunContainer</p>
<p>RBM算法的核心步骤如下：
（1）数组中每个数除以2^16，以商,余数的形式表示出来
（2）将相同商的归在一个Container，如果Contaniner中数值容量超过4096使用bitmap的形式来存储一个Container中的数，如果没有超过那就使用short[]来存储，如果是连续数组那就使用RunContainer来存储</p>
<h2>四、Trie前缀树原理</h2>
<p style="color:red;">在了解ElasticSearch中FST算法前，我们先了解Trie的概念，方便我们之后理解FST。</p>
<ol>
<li>
<h4>基本概念</h4>
<p>**概念：**Trie又被称为前缀树、字典树。Trie利用字符串的公共前缀来高效的存储和检索字符串数据集中的关键词，最大限度的减少无畏的字符串比较。典型应用是用于统计，排序和保存大量的字符串（但不仅限于字符串），所以经常被搜索引擎系统用于文本词频统计。</p>
<ul>
<li>优点：利用字符串的公共前缀来减少查询时间，最大限度地减少无谓的字符串比较，查询效率比哈希树高。</li>
<li>缺点：基于空间换时间的思想，所以系统中若存在大量的没有公共前缀的字符串则会消耗大量内存。</li>
</ul>
<p>**核心细想：**空间换时间。利用字符串的公共前缀来降低查询时间的开销已达到提高效率的目的。</p>
<p><strong>三个特性：</strong></p>
<ul>
<li>根节点不包含字符，除根节点外每一个节点都只包含一个字符。</li>
<li>从根节点到某一节点，路径上经过的字符连接起来，为该节点对应的字符串。</li>
<li>每个节点的所有子节点包含的字符都不相同。</li>
</ul>
</li>
<li>
<h4>算法图解</h4>
<pre v-pre=""><code v-pre="">msb   msbtench   msn   witech
</code></pre>
<p><img src="C:%5CUsers%5Cxiaowu%5CAppData%5CRoaming%5CTypora%5Ctypora-user-images%5Cimage-20230106172113167.png" alt="image-20230106172113167"></p>
</li>
</ol>
<h2>五、FST的构建过程</h2>
<h3>5.1</h3>
<h2>六、FST在Lucene中的实现原理</h2>
<h3>6.1</h3>
<h2>七、tip和tim文件的内部结构</h2>
<h3>7.1</h3>
<h2>八、FST在Lucene中的构建和读取过程</h2>
<h3>8.1</h3>
<h2>九、集群、节点和分片</h2>
<h3>9.1</h3>
<h2>十、索引和文档的概念</h2>
<h3>10.1</h3>
</body></html></section></template>

