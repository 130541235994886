<template>
  <div class="app-container">
    <!-- 左侧边栏 -->
    <div class="sidebar">
      <!-- 用户信息区域 -->
      <div class="user-section">
        <div class="logo">
          <img src="@/assets/img.png" alt="用户头像">
        </div>
        <button class="new-note">+ 新建</button>
      </div>

      <!-- 导航菜单 -->
      <div class="nav-menu">
        <div class="menu-item">
          <span class="icon">
            <svg width="16" height="16" viewBox="0 0 16 16">
              <path d="M6.5 14v-4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v4h3.5V8.5L8 4.5l-5 4v5.5h3.5z" fill="currentColor"/>
              <path d="M8 2.5l7 5.5h-2l-5-4-5 4H1l7-5.5z" fill="currentColor"/>
            </svg>
          </span>
          <span class="text">首页</span>
        </div>
        <div class="menu-item">
          <span class="icon">📝</span>
          <span class="text">笔记设计</span>
        </div>
        <div class="menu-item">
          <span class="icon">🔍</span>
          <span class="text">我的分享</span>
        </div>
        <div class="menu-item">
          <span class="icon">⭐</span>
          <span class="text">收藏</span>
        </div>
        <div class="menu-item">
          <span class="icon">🗑️</span>
          <span class="text">回收站</span>
        </div>
        <div class="menu-item">
          <span class="icon">⚙️</span>
          <span class="text">云同步</span>
        </div>
      </div>

      <!-- 底部按钮 -->
      <div class="bottom-copyright">
        <p>小抱笔记 zdzaq.cn 版权所有</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022033978号-1</a>
        </p>
      </div>
    </div>

    <!-- 主内容区 -->
    <div class="main-content">
      <div class="content-wrapper">
        <!-- 左侧列表区域 -->
        <div class="list-section">
          <!-- 搜索框移到这里 -->
          <div class="search-bar">
            <div class="search-input-wrapper">
              <span class="search-icon">🔍</span>
              <input type="text" placeholder="搜索笔记(Ctrl/Shift)" />
            </div>
            <button class="menu-btn">≡</button>
          </div>
          
          <!-- 文件列表 -->
          <div class="file-list">
            <div v-for="file in files" 
                 :key="file.id"
                 :class="['file-item', file.type, file.isSubItem ? 'sub-item' : '']"
                 :data-id="file.id">
              <span class="file-icon">{{ file.icon }}</span>
              <div class="file-info">
                <div :class="file.type === 'folder' ? 'folder-title' : 'file-title'">
                  <span class="title">{{ file.title }}</span>
                  <span v-if="file.type === 'folder'" class="count">{{ file.count }}个文件</span>
                </div>
                <div v-if="file.type !== 'folder'" class="file-meta">{{ file.meta }}</div>
              </div>
              <div class="more-actions">
                <button class="more-btn" title="更多操作" @click.stop.prevent="showPopup">⋯</button>
              </div>
            </div>
          </div>
        </div>

        <!-- 内容展示区 -->
        <div class="content-display">
          <div class="content-header">
            <h1 class="note-title">无标题笔记</h1>
            <div class="note-meta">
              <span>最后编辑于 2024.02.18</span>
              <div class="note-actions">
                <button>分享</button>
                <button>更多</button>
              </div>
            </div>
          </div>
          <v-md-preview :text="text"></v-md-preview>
        </div>
      </div>
    </div>

    <!-- 修改弹层位置 -->
    <Teleport to="body">
      <div v-if="popupVisible" 
           class="popup-menu"
           :style="{ left: popupX + 'px', top: popupY + 'px' }"
           @click.stop>
        <div class="popup-content">
          <button class="popup-item">编辑</button>
          <button class="popup-item">删除</button>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped>
.app-container {
  display: flex;
  height: 100vh;
  background-color: #fff;
}

.sidebar {
  width: 250px;
  height: 100%;
  background: var(--fill-9, #ffffff);
  border-right: 1px solid var(--line-2, #eceff4);
  display: flex;
  flex-direction: column;
}

.user-section {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.logo img {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}

.new-note {
  width: 100%;
  padding: 8px;
  background: var(--brand-7, #326cfe);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-menu {
  padding: 10px 0;
}

.menu-item {
  padding: 12px 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--text-4, #4e5a70);
  cursor: pointer;
}

.menu-item:hover {
  background: var(--sidebar-hover-selected, #f0f3f5);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: var(--text-4, #4e5a70);
}

.menu-item:hover .icon {
  color: var(--brand-7, #326cfe);
}

.text {
  font-size: 15px;
  flex: 1;
}

.bottom-copyright {
  position: absolute;
  bottom: 0;
  width: 250px;
  background: #fff;
  font-size: 12px;
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid var(--line-2, #eceff4);
  color: var(--text-3, #7a8599);
}

.bottom-copyright p {
  margin: 0;
  line-height: 22px;
}

.bottom-copyright a {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
}

.bottom-copyright a:hover {
  color: var(--text-4, #4e5a70);
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.list-section {
  width: 300px;
  border-right: 1px solid var(--line-2, #eceff4);
  display: flex;
  flex-direction: column;
}

.search-bar {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-input-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 12px;
  color: var(--text-3, #7a8599);
  font-size: 14px;
}

.search-bar input {
  flex: 1;
  padding: 8px 12px 8px 32px;
  border: 1px solid var(--line-2, #eceff4);
  border-radius: 16px;
  font-size: 13px;
  color: var(--text-4, #4e5a70);
  min-width: 0;
  transition: border-color 0.2s;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--brand-7, #326cfe);
}

.menu-btn {
  padding: 6px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--text-4, #4e5a70);
  flex-shrink: 0;
}

.file-list {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.file-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid var(--line-2, #eceff4);
}

/* 最后一个文件项不需要底部边框 */
.file-item:last-child {
  border-bottom: none;
}

.file-item:hover {
  background-color: var(--fill-3, #f0f3f5);
}

.file-info {
  flex: 1;
}

.file-title {
  font-size: 14px;
  color: var(--text-5, #232d47);
  margin-bottom: 4px;
}

.file-meta {
  font-size: 12px;
  color: var(--text-3, #7a8599);
}

.content-display {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.content-header {
  padding: 12px 0;
  margin-top: 4px;
  border-bottom: 1px solid var(--line-2, #eceff4);
}

.note-title {
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 8px 0;
  color: var(--text-5, #232d47);
}

.note-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-3, #7a8599);
  font-size: 12px;
}

.note-actions {
  display: flex;
  gap: 10px;
}

.note-actions button {
  padding: 4px 10px;
  border: 1px solid var(--line-2, #eceff4);
  border-radius: 4px;
  background: white;
  color: var(--text-4, #4e5a70);
  cursor: pointer;
}

.content-editor {
  flex: 1;
  padding: 20px 0;
}

.editor-placeholder {
  color: var(--text-2, #a9b2c2);
  font-size: 14px;
}

/* 调整子项的边框样式 */
.sub-item {
  padding-left: 32px !important;
  position: relative;
  border-bottom: 1px solid var(--line-2, #eceff4);
}

/* 文件夹项的边框样式 */
.folder {
  font-weight: 500;
  border-bottom: 1px solid var(--line-2, #eceff4);
}

.folder .file-icon {
  color: var(--brand-7, #326cfe);
}

.folder-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-5, #232d47);
  font-size: 14px;
}

.folder .count {
  font-size: 12px;
  color: var(--text-3, #7a8599);
  font-weight: normal;
}

.folder .file-meta {
  display: none;
}

.more-actions {
  opacity: 0;
  transition: opacity 0.2s;
}

.file-item:hover .more-actions {
  opacity: 1;
}

.more-btn {
  padding: 4px 8px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--text-3, #7a8599);
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.2s;
}

.more-btn:hover {
  background-color: var(--fill-2, #f4f6f7);
  color: var(--text-5, #232d47);
}

/* 添加工具提示样式 */
[title] {
  position: relative;
}

[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: var(--text-5, #232d47);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none;
  margin-bottom: 4px;
}

.popup-menu {
  position: fixed;
  width: 100px;
  background: white;
  border: 1px solid var(--line-2, #eceff4);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.popup-item {
  padding: 6px 12px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  color: var(--text-4, #4e5a70);
  font-size: 13px;
  transition: all 0.2s;
}

.popup-item:hover {
  background-color: var(--fill-2, #f4f6f7);
}

.ghost {
  opacity: 0.5;
  background: var(--fill-2, #f4f6f7);
}

.dragging {
  cursor: grabbing;
}

.file-item {
  cursor: grab;
}

.file-item:active {
  cursor: grabbing;
}
</style>

<script>
import { defineComponent } from 'vue'
import Sortable from 'sortablejs'

export default defineComponent({
  name: 'SidebarPanel',
  components: {

  },
  data() {
    return {
      text: "",
      popupVisible: false,
      popupX: 0,
      popupY: 0,
      files: [
        {
          id: 1,
          type: 'folder',
          icon: '📁',
          title: '我的文件夹',
          count: 3
        },
        {
          id: 2,
          type: 'file',
          icon: '📄',
          title: '神的对策',
          meta: '2022.11.25',
          isSubItem: true
        },
        {
          id: 3,
          type: 'file',
          icon: '📄',
          title: '无标题笔记(2)',
          meta: '2024.02.18 · 143 B',
          isSubItem: true
        },
        {
          id: 4,
          type: 'file',
          icon: '📄',
          title: '无标题笔记(1)',
          meta: '2024.02.18 · 143 B',
          isSubItem: true
        },
        {
          id: 5,
          type: 'file',
          icon: '📄',
          title: '无标题笔记',
          meta: '2024.02.18 · 143 B'
        }
      ],
      noteContent: ''
    }
  },
  mounted() {
    this.loadMarkdownFile(); 
    const fileList = this.$el.querySelector('.file-list');
    new Sortable(fileList, {
      animation: 150,
      ghostClass: 'ghost',
      onStart: (evt) => {
        evt.item.classList.add('dragging');
      },
      onEnd: (evt) => {
        evt.item.classList.remove('dragging');
        this.handleDragEnd(evt);
      }
    });
  },
  methods: {
    loadMarkdownFile() {
        const filePath =  require('@/assets/md/11.md');
        fetch(filePath)
            .then(response => response.text())
            .then(data => {
              console.log(data);
                this.text = data; // 将文件内容赋值给 text
            })
            .catch(error => {
                console.error('加载文件失败:', error);
            });
    },
    showPopup(event) {
      event.stopPropagation();
      const rect = event.target.getBoundingClientRect();
      this.popupX = rect.left;
      this.popupY = rect.bottom + 5;
      this.popupVisible = true;

      this.$nextTick(() => {
        document.addEventListener('click', this.closePopup);
      });
    },
    closePopup(event) {
      if (!event.target.closest('.popup-menu') && !event.target.closest('.more-btn')) {
        this.popupVisible = false;
        document.removeEventListener('click', this.closePopup);
      }
    },
    handleDragEnd(evt) {
      const { newIndex, oldIndex } = evt;
      const item = this.files.splice(oldIndex, 1)[0];
      this.files.splice(newIndex, 0, item);
      
      const prevItem = newIndex > 0 ? this.files[newIndex - 1] : null;
      if (prevItem && prevItem.type === 'folder') {
        item.isSubItem = true;
      } else if (prevItem && prevItem.type !== 'folder') {
        item.isSubItem = false;
      }
      
      this.updateFolderCount();
    },
    updateFolderCount() {
      this.files.forEach(file => {
        if (file.type === 'folder') {
          file.count = this.files.filter(f => f.isSubItem).length;
        }
      });
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closePopup);
  }
})
</script> 